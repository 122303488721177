.pre-scrollable {
    max-height: 500px;
    overflow-y: scroll;
}

.campaign {
    font-size: 70%;
}

.txt-dark-yellow {
    color: #b66c21;
}

.txt-dark-red {
    color: #b62121;
}

.bodyL .wallboard.pub-performance-report {
    font-size: 150%;
}

.bodyL .wallboard.pub-performance-report>.wb-row>.wb-col>.card.head {
    width: 280px;
    height: 150px;
}
.bodyL .wallboard.statistic.pub-performance-report>.wb-row>.wb-col>.card.head {
    height: 165px;
}

.traffic{
    border: 1px solid #b3aeae;
    padding: 2px 6px;
    border-radius: 10px;
}

.rank{
    border: 1px solid red;
    padding: 2px 6px;
    border-radius: 10px;
    color: red;
}

.rank img{
   width: 11px;
}

.bodyL .sttCall {
    font-size: 16px;
}
.bodyM .sttCall {
    font-size: 12px;
}
.bodyX .sttCall {
    font-size: 11px;
}

.wallboard>.wb-row>.wb-col {
    border-radius: 5px;
}

.highlightBorder {
    border: 2px solid;
    border-image: linear-gradient(to right, red, orange) 1;
}

.bodyL .tagCPL {
    color: rgb(0, 0, 0);
    position: absolute;
    left: 3px;
    top: 3px;
    font-size: 10px;
    border: 1px solid rgb(204, 204, 204);
    padding: 2px;
    border-radius: 4px;
}
.bodyM .tagCPL {
    color: rgb(0, 0, 0);
    position: absolute;
    left: 3px;
    top: 3px;
    font-size: 10px;
    border: 1px solid rgb(204, 204, 204);
    padding: 2px;
    border-radius: 4px;
}
.bodyX .tagCPL {
    color: rgb(0, 0, 0);
    position: absolute;
    left: 3px;
    top: 3px;
    font-size: 10px;
    border: 1px solid rgb(204, 204, 204);
    padding: 2px;
    border-radius: 4px;
}

.loadMore {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 160px;
}


.loadMore button {
    font-size: 12px;
}

.scrollX {
    overflow-x: scroll;
    display: inline-block !important;
}

.bodyL .scrollX {
    width: 271%;
}

.bodyM .scrollX {
    width: 218%;
}

.bodyX .scrollX {
    width: 200%;
}

.bodyX .loadMore {
    height: 120px;
}

@media only screen and (max-width: 1200px) {
    .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
        padding: 0;
    }
    .bodyL .wallboard.network-performance-report>.wb-row>.wb-col>.card.head {
        height: 60px !important;
    }
    .bodyL .wallboard.network-performance-report>.wb-row>.wb-col>.card.tail {
        height: 60px !important;
    }
    .bodyL .wallboard.pub-performance-report>.wb-row>.wb-col>.card.head {
        width: 80px !important;
        height:75px !important;
    }

    .bodyL .wallboard.team-performance-report>.wb-row>.wb-col>.card {
        width: 80px !important;
        height: 70px !important;
    }

    .bodyL .wallboard.rel-performance-component>.wb-row>.wb-col>.card {
        width: 80px !important;
        height: 70px !important;
    }

    .wallboard>.wb-row>.wb-col>.card p {
        font-size: 5.5px !important;
        padding: 1px !important;
        margin: 0 !important;
    }

    .bodyM .wallboard.pub-performance-report>.wb-row>.wb-col>.card.tail {
        height: 60px !important;
    }
    .bodyM .wallboard.statistic.pub-performance-report>.wb-row>.wb-col>.card.tail {
        height: 70px !important;
    }

    .bodyM .wallboard.pub-performance-report>.wb-row>.wb-col>.card.head {
        height: 60px !important;
    }
    .bodyM .wallboard.statistic.pub-performance-report>.wb-row>.wb-col>.card.head {
        height: 70px !important;
    }

    .bodyX .wallboard.pub-performance-report>.wb-row>.wb-col>.card.tail {
        height: 60px !important;
    }
    .bodyX .wallboard.statistic.pub-performance-report>.wb-row>.wb-col>.card.tail {
        height: 70px !important;
    }
    .bodyX .wallboard.pub-performance-report>.wb-row>.wb-col>.card.head {
        height: 60px !important;
    }
    .bodyX .wallboard.statistic.pub-performance-report>.wb-row>.wb-col>.card.head {
        height: 70px !important;
    }

    .wallboard>.wb-row>.wb-col>.card {
        width: 67px !important;
    }

    .bodyL .wallboard.pub-performance-report>.wb-row>.wb-col>.card.tail {
        height: 75px !important;
    }
    .bodyL .wallboard.pub-performance-report>.wb-row>.wb-col>.loadMore,
    .bodyM .wallboard.pub-performance-report>.wb-row>.wb-col>.loadMore,
    .bodyX .wallboard.pub-performance-report>.wb-row>.wb-col>.loadMore {
        height: 75px !important;
    }
    .bodyL .wallboard.pub-performance-report>.wb-row>.wb-col>.loadMore button,
    .bodyM .wallboard.pub-performance-report>.wb-row>.wb-col>.loadMore button,
    .bodyX .wallboard.pub-performance-report>.wb-row>.wb-col>.loadMore button{
        font-size: 6px;
        padding: 4px;
    }
    .bodyL .scrollX,
    .bodyM .scrollX,
    .bodyX .scrollX {
        width: 1300px;
    }

    .card-body {
        flex: 1 1 auto;
        min-height: 1px;
        padding: 0.25rem;
    }
    .traffic {
        font-size: 4px !important;
    }
    .rank {
        font-size: 4px !important;
    }
    .rank img{
        width: 5px;
    }
    .wallboard>.wb-row>.wb-col>.card p.sttCall {
        font-size: 6px !important;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .sttCall .mr-1 {
        margin-right: 1px !important;
        width: 30%;
    }
    .bodyX .wallboard>.wb-row>.wb-col>.card, .bodyM .wallboard>.wb-row>.wb-col>.card, .bodyL .wallboard>.wb-row>.wb-col>.card {
        width: 110px !important;
    }
    .bodyX .wallboard.pub-performance-report>.wb-row>.wb-col>.card.head.more, .bodyX .wallboard.pub-performance-report>.wb-row>.wb-col>.card.tail.more,
    .bodyM .wallboard.pub-performance-report>.wb-row>.wb-col>.card.head.more, .bodyM .wallboard.pub-performance-report>.wb-row>.wb-col>.card.tail.more
    {
        height: 75px !important;
    }
    .bodyL .tagCPL {
        font-size: 3px;
        padding: 2px;
    }
    .bodyM .tagCPL {
        font-size: 3px;
        padding: 2px;
    }
    .bodyX .tagCPL {
        font-size: 3px;
        padding: 2px;
    }
}

.bodyL .wallboard.pub-performance-report>.wb-row>.wb-col>.card.tail {
    height: 150px;
}
.bodyL .wallboard.statistic.pub-performance-report>.wb-row>.wb-col>.card.tail {
    height: 165px;
}

.bodyL .wallboard.pub-performance-report>.wb-row>.wb-col>.card.head.more,
.bodyL .wallboard.pub-performance-report>.wb-row>.wb-col>.card.tail.more {
    height: 170px;
}

.bodyM .wallboard.pub-performance-report {
    font-size: 120%;
}

.bodyM .wallboard.pub-performance-report>.wb-row>.wb-col>.card.head {
    width: 220px;
    height: 120px;
}
.bodyM .wallboard.statistic.pub-performance-report>.wb-row>.wb-col>.card.head {
    height: 140px;
}

.bodyM .wallboard.pub-performance-report>.wb-row>.wb-col>.card.tail {
    width: 220px;
    height: 120px;
}
.bodyM .wallboard.statistic.pub-performance-report>.wb-row>.wb-col>.card.tail {
    height: 140px;
}

.bodyM .wallboard.pub-performance-report>.wb-row>.wb-col>.card.head.more,
.bodyM .wallboard.pub-performance-report>.wb-row>.wb-col>.card.tail.more {
    height: 140px;
}

.bodyX .wallboard.pub-performance-report {
    font-size: 100%;
}

.bodyX .wallboard.pub-performance-report>.wb-row>.wb-col>.card.head {
    width: 200px;
    height: 100px;
}
.bodyX .wallboard.statistic.pub-performance-report>.wb-row>.wb-col>.card.head {
    height: 125px;
}

.bodyX .wallboard.pub-performance-report>.wb-row>.wb-col>.card.tail {
    width: 200px;
    height: 100px;
}
.bodyX .wallboard.statistic.pub-performance-report>.wb-row>.wb-col>.card.tail {
    height: 125px;
}

.bodyX .wallboard.pub-performance-report>.wb-row>.wb-col>.card.head.more,
.bodyX .wallboard.pub-performance-report>.wb-row>.wb-col>.card.tail.more {
    height: 120px;
}
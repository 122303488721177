.table-Wrap{
    overflow-x: auto;
    width: 100%;
}
.table-Wrap table{
    white-space: nowrap;
}
.table-Wrap table th{
    padding: 5px 10px;
}
/* width */
::-webkit-scrollbar {
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c1c1c1; 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #808080; 
}
#root {
    overflow: hidden;
}

.v-top {
    vertical-align: top;
}

.fullscreen .sb-topnav {
    display: none;
}

.font-60pc {
    font-size: 60%;
}

.font-70pc {
    font-size: 70%;
}

.font-80pc {
    font-size: 80%;
}

.font-120pc {
    font-size: 120%;
}

.mb-5px {
    margin-bottom: 5px;
}

.mb-sm-1 {
    margin-bottom: 0.25rem !important;
}

.scroll-x {
    overflow-x: scroll;
}

table th {
    background-color: gray;
    color: white;
    padding: 15px 0;
}

table td {
    padding: 15px 5px;
    white-space: nowrap;
}

table tbody tr:nth-child(odd) {
    background: rgb(243, 243, 243);
}

table tbody tr td.white {
    background: #FFF;
}

table tbody tr td.gray {
    background: #CCC;
}

table tbody tr.total,
table tbody tr.total {
    background: rgb(45, 47, 150);
    color: white;
}

table tbody tr.total .percent {
    color: white;
}

table tbody tr:hover {
    background: rgba(45, 47, 150, 0.22) !important;
}

.table-sm {
    font-size: 80%;
}

.table-sm th,
.table-sm td {
    font-size: 90%;
    white-space: nowrap;
    padding: 10px 20px;
}

.table-sm th:first-child,
.table-sm td:first-child {
    text-align: left;
}

.table-sm th,
.table-sm td {
    text-align: center;
}

.wallboard {
    font-size: 150%;
}

.wallboard>.wb-row {
    display: block;
    white-space: nowrap;
    padding: 0.05rem 0;
}

.wallboard>.wb-row>.wb-col:first-child .card {
    background-color: #eeeeee;
}

.wallboard>.wb-row>.wb-col {
    display: inline-block;
    overflow: hidden;
}

.wallboard>.wb-row>.wb-col>.card {
    display: inline-block;
    vertical-align: top;
    width: 240px;
    height: 120px;
}

.wallboard>.wb-row>.wb-col>.card.h150 {
    height: 150px;
}

.wallboard>.wb-row>.wb-col>.card:hover {
    border-width: 2px;
    border-color: #17a2b8 !important;
}

.wallboard>.wb-row>.wb-col>.card.highlightBorder:hover {
    border: 2px solid;
    border-image: linear-gradient(to left, red, orange) 1;
}

.wallboard>.wb-row>.wb-col>.card.more {
    height: 185px;
}

.wallboard>.wb-row>.wb-col>.card.more4 {
    height: 200px;
}

.wallboard>.wb-row>.wb-col>.card.w200 {
    width: 200px;
}

.wallboard>.wb-row>.wb-col>.card p {
    padding: 0;
    margin: 0;
}

.wallboard .warning,
.wallboard .warning .text-info,
.wallboard .warning .text-success,
.wallboard .warning .text-danger,
.wallboard .danger,
.wallboard .danger .text-info,
.wallboard .danger .text-success,
.wallboard .danger .text-danger {
    xcolor: white !important;
}

.wallboard .danger,
.wallboard-desc .danger,
.wallboard .critical,
.wallboard-desc .critical {
    background-color: #eecfcf;
}

.wallboard .warning,
.wallboard-desc .warning {
    background-color: #fefdd0;
}

.wallboard .card-body {
    padding: 0.25rem;
}

.max-height-100 {
    max-height: 100px;
    overflow: hidden;
}

.summary-box-success,
.border.summary-box-success {
    border-left: 5px solid #28a745 !important;
    border-radius: 5%;
}

.summary-box-primary,
.border.summary-box-primary {
    border-left: 5px solid #007bff !important;
    border-radius: 5%;
}

.summary-box-info,
.border.summary-box-info {
    border-left: 5px solid #17a2b8 !important;
    border-radius: 5%;
}

.summary-box-warning,
.border.summary-box-warning {
    border-left: 5px solid #ffe48c !important;
    border-radius: 5%;
}

.summary-box-gray,
.border.summary-box-gray {
    border: 1px solid #aaaaaa !important;
}

.jumbo {
    font-size: 200%;
    font-weight: 500;
}

thead.small {
    font-size: 100%;
    font-weight: 500;
}

tbody.small tr td {
    font-size: 100%;
    font-weight: 500;
}

thead.big {
    font-size: 130%;
    font-weight: 500;
}

tbody.big tr td {
    font-size: 120%;
    font-weight: 500;
}

thead.f150 {
    font-size: 150%;
    font-weight: 500;
}

tbody.f150 tr td {
    font-size: 150%;
    font-weight: 500;
}

.td-yellow-red {
    background: rgb(233, 213, 123);
    color: rgb(182, 70, 70);
}

.td-blue-black {
    background-color: rgb(93, 166, 207);
}

.td-white-black {
    background-color: rgb(255, 255, 255);
}

.f150 .digit1 {
    width: 15px;
}

.f150 .digit2 {
    width: 30px;
}

.f150 .digit3 {
    width: 45px;
}

.f150 .digit4 {
    width: 60px;
}

.f150 .digit5 {
    width: 75px;
}

.f150 .digit6 {
    width: 90px;
}

.btn-floating {
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 1;
    display: inline-block;
    padding: 0;
    margin: 10px;
    overflow: hidden;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 50%;
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    width: 47px;
    height: 47px;
}

.btn-floating i {
    display: inline-block;
    width: inherit;
    color: #fff;
    text-align: center;
    font-size: 1.25rem;
    line-height: 47px;
}

.btn-float-box {
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 1;
    display: inline-block;
}

.btn-float-box .btn-float {
    display: inline-block;
    padding: 0;
    margin: 10px;
    overflow: hidden;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 50%;
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    width: 47px;
    height: 47px;
}

.btn-float-box .btn-float i {
    display: inline-block;
    width: inherit;
    color: #fff;
    text-align: center;
    font-size: 1.25rem;
    line-height: 47px;
}

.percent {
    font-size: 80%;
    color: rgb(119, 62, 62);
    margin-left: 0.5rem;
}

.indent-25 {
    text-indent: 25px;
}

.indent-50 {
    text-indent: 50px;
}

#userCtrl {
    cursor: pointer;
}

.popup-user {
    display: none;
    position: absolute;
    top: 56px;
    right: 0;
    border-radius: 0 0 0 5px;
    z-index: 1040;
}

#userCtrl.active .popup-user {
    display: inline-block;
}

#userCtrl.active .popup-user ul {
    width: 200px;
}

#userCtrl.active .popup-user ul li {
    width: 100%;
}

body.ajax-loading .loading {
    display: table;
}

.loading {
    display: none;
    color: #222;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    z-index: 9999;
}

.loading-center {
    display: table-cell;
    vertical-align: middle;
    padding: 20px;
}

.loading-content {
    text-align: center;
}

.text-indent-0 {
    text-indent: 0px;
}

.row-level-1,
.row-level-1 .percent {
    font-weight: bold;
    background: #2d2f96 !important;
    color: #fff;
}

.row-level-2 td:first-child {
    text-indent: 15px;
}

.row-level-2 td {
    text-indent: 15px;
    background: #8788df !important;
}

.row-level-2 i {
    cursor: pointer;
}

.row-level-3 td:first-child {
    text-indent: 30px;
}

.row-level-3 td {
    text-indent: 15px;
    background: #9e9e9e !important;
}

.row-level-4 td:first-child {
    text-indent: 40px;
}

.row-level-4 td {
    text-indent: 15px;
    background: #ffffff !important;
}

.cursor-pointer {
    cursor: pointer;
}

.weight-500 {
    font-weight: 500;
}

.bg-dangerous {
    background-color: #eecfcf !important;
}

.bg-dangerous-blink {
    background-color: #eecfcf;
    animation: bgblinking 1s infinite;
}

@keyframes bgblinking {
    0% {
        background-color: #eecfcf;
    }
    50% {
        background-color: white;
    }
    100% {
        background-color: #eecfcf;
    }
}

@media (max-width: 1140px) {
    .top-ticker .content {
        animation: floatText 15s infinite linear;
        padding-left: 100%;
    }
    .top-ticker .content:hover {
        animation-play-state: paused;
    }
    @keyframes floatText {
        to {
            transform: translateX(-100%);
        }
    }
}

.icon-collapse {
    font-size: 70%;
    color: black;
}

.static-modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.static-modal-content {
    background-color: #fefefe;
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.wallboard>.wb-row>.wb-col>.card.w180 {
    width: 180px;
}

.w230 {
    width: 230px;
}

.invisible {
    position: absolute;
    top: -1000px;
    left: -1000px;
}

.pointer {
    cursor: pointer;
}

.max-width-500 {
    max-width: 500px;
    overflow-x: scroll;
}

.min-height-500 {
    min-height: 500px;
}

.mnu-size {
    color: #fff;
}

.mnu-size.active,
.mnu-size:hover {
    color: #17a2b8;
    font-weight: 500;
}

.wallboard-block table th {
    color: #ffffff;
    background: #00639c;
}

.wallboard-block table {
    table-layout: fixed;
}

.wallboard-block table th,
.wallboard-block table td {
    padding: 5px 10px;
}

.filter {
    font-size: 130%;
}

.filter .form-check-input {
    margin-top: 0.7rem;
}

.red {
    color: rgb(182, 70, 70)
}

.dark-red {
    color: rgb(129, 44, 44)
}

.blue {
    color: rgb(59, 60, 165)
}

.green {
    color: rgb(48, 139, 68)
}

.yellow {
    color: rgb(211, 185, 72)
}

.dark-yellow {
    color: rgb(172, 150, 55)
}

.bg-dark {
    background-color: rgb(34, 29, 29)
}

.bg-red {
    background-color: rgb(182, 70, 70)
}

.bg-dark-red {
    background-color: rgb(129, 44, 44)
}

.bg-blue {
    background-color: rgb(59, 60, 165)
}

.bg-green {
    background-color: rgb(48, 139, 68)
}

.bg-yellow {
    background-color: rgb(211, 185, 72)
}

.bg-dark-yellow {
    background-color: rgb(172, 150, 55)
}

.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}

.label-big {
    font-size: 100%;
}

.badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: rgb(0, 0, 0);
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
}

.selectCustom {
    position: relative;
    background-color: #e6e6e6;
    width: auto;
    float: right;
    max-width: 100%;
    border-radius: 2px;
    height: 35px;
    margin-right: 5px !important;
    margin-bottom: 0 !important;
  }
  
  .selectCustom select {
    font-family: "helvetica neue", helvetica;
      font-size: 0.9rem;
      font-weight: 500;
      max-width: 100%;
      padding: 4px 24px 4px 10px;
      border: none;
      background-color: transparent;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
  }
  
  .selectCustom select:active, select:focus {
    outline: none;
    box-shadow: none;
  }
  
  .selectCustom:after {
    content: " ";
      position: absolute;
      top: 50%;
      margin-top: -2px;
      right: 8px;
      width: 0; 
      height: 0; 
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #aaa;
  }

.panelDefault {
    border-color: #ddd;
    margin-top: 10px;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid #0000003d;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}

.panelHeader {
    color: #333;
    background-color: #6c757d2b;
    border-color: #ddd;
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.cp_cat_2 {
    display: none;
}

.cp_cat_2.active {
    display: block;
}

#btnToggle .fa-arrow-down {
    display: block;
}

#btnToggle .fa-arrow-up {
    display: none;
}

#btnToggle.active .fa-arrow-down {
    display: none;
}

#btnToggle.active .fa-arrow-up {
    display: block;
}
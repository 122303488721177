.wallboard>.wb-row>.wb-col>.card {
    width: 280px;
}

.wallboard>.wb-row>.wb-col>.card .title {
    color: #972323;
}

.react-datepicker-wrapper {
    width: 100%;
}
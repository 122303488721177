.wallboard>.wb-row>.wb-col>.card {
    width: 280px;
}

.wallboard>.wb-row>.wb-col>.card .title {
    color: #972323;
}

.bodyL .wallboard.rel-performance-component {
    font-size: 150%;
}

.bodyL .wallboard.rel-performance-component>.wb-row>.wb-col>.card {
    width: 280px;
    height: 200px;
}

.bodyM .wallboard.rel-performance-component {
    font-size: 120%;
}

.bodyM .wallboard.rel-performance-component>.wb-row>.wb-col>.card {
    width: 230px;
    height: 160px;
}

.bodyX .wallboard.rel-performance-component {
    font-size: 100%;
}

.bodyX .wallboard.rel-performance-component>.wb-row>.wb-col>.card {
    width: 200px;
    height: 130px;
}
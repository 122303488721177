.prority{
    margin-top: 20px;
}
.prority h1{
    color: #9797aa;
    font-size: 16px;
}
.prority table th{
    text-align: center;
    padding: 6px 6px;
    font-size: 14px;
}
.prority table td{
    text-align: center;
    padding: 8px 6px;
    font-size: 14px;
}
.prority table tr:hover{
    background: rgb(45 47 150 / 22%);
}
.prority table tr:nth-child(2n+1){
    background-color: #F3F3F3;
}
.prority table tr:nth-child(2n+1):hover{
    background: rgb(45 47 150 / 22%);
}
.prority table .borderRight{
    border-right: 1px solid #E4E5EF;
}
.cp_cat_2 {
    display: none;
}

.cp_cat_2.active {
    display: block;
}

.btn-toggle,
.btn-toggle:hover {
    display: inline-block;
    text-decoration: none;
}

.btn-toggle .fa-angle-down {
    display: block;
}

.btn-toggle .fa-angle-up {
    display: none;
}

.btn-toggle.active .fa-angle-down {
    display: none;
}

.btn-toggle.active .fa-angle-up {
    display: block;
}

.table-no-pad th,
.table-no-pad td {
    padding: 0
}

table tbody.tbody1 tr:nth-child(odd) {
    background: rgb(255, 218, 175);
}

table tbody.tbody1 tr:nth-child(even) {
    background: rgb(255, 192, 119);
}

table tbody.tbody2 tr:nth-child(odd) {
    background: rgb(172, 205, 255);
}

table tbody.tbody2 tr:nth-child(even) {
    background: rgb(102, 163, 255);
}

table tbody.tbody3 tr:nth-child(odd) {
    background: rgb(172, 255, 190);
}

table tbody.tbody3 tr:nth-child(even) {
    background: rgb(116, 255, 146);
}
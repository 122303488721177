.top-ticker {
    position: relative;
    display: none;
    display: flex;
    padding-left: 0;
    height: 56px;
    z-index: 1039;
    background-color: #343a40 !important;
}

.top-ticker .content {
    margin: auto;
    padding: 7px 0;
    text-align: center;
}

.top-ticker .content .box {
    margin: 0 10px;
    text-align: center;
    white-space: nowrap;
}

.top-ticker .content .box .white {
    color: white;
}

.top-ticker .content .box .red {
    color: red;
}

.top-ticker .content .box .yellow {
    color: yellow;
}

.top-ticker .content .box .green {
    color: green;
}

.top-ticker .content .box .blue {
    color: blue;
}

.top-ticker .content .box span {
    padding: 0;
    margin: 0;
}

.top-ticker .content .box .title {
    font-size: 18px;
    line-height: 18px;
}

.top-ticker .content .box .value {
    font-size: 30px;
    line-height: 30px;
}

.top-ticker .dangerous {
    color: red;
    animation: blinking 1s infinite;
}

@keyframes blinking {
    0% {
        color: red;
    }
    50% {
        color: white;
    }
    100% {
        color: red;
    }
}

@media (max-width: 1140px) {
    .top-ticker .content {
        animation: floatText 15s infinite linear;
        padding-left: 100%;
    }
    .top-ticker .content:hover {
        animation-play-state: paused;
    }
    @keyframes floatText {
        to {
            transform: translateX(-100%);
        }
    }
}
.pre-scrollable {
    max-height: 500px;
    overflow-y: scroll;
}

.campaign {
    font-size: 70%;
}

.txt-dark-yellow {
    color: #b66c21;
}

.txt-dark-red {
    color: #b62121;
}

.bodyL .wallboard.network-performance-statistic-report {
    font-size: 150%;
}

.bodyL .wallboard.network-performance-statistic-report>.wb-row>.wb-col>.card.head,
.bodyL .wallboard.network-performance-statistic-report>.wb-row>.wb-col>.card.tail {
    height: 150px;
}

.bodyL .wallboard.network-performance-statistic-report>.wb-row>.wb-col>.card.head {
    width: 280px;
}


/* .bodyL .wallboard.network-performance-statistic-report>.wb-row>.wb-col>.card.head.more,
.bodyL .wallboard.network-performance-statistic-report>.wb-row>.wb-col>.card.tail.more {
    height: 150px;
} */

.bodyM .wallboard.network-performance-statistic-report {
    font-size: 120%;
}

.bodyM .wallboard.network-performance-statistic-report>.wb-row>.wb-col>.card.head,
.bodyM .wallboard.network-performance-statistic-report>.wb-row>.wb-col>.card.tail {
    height: 120px;
}

.bodyM .wallboard.network-performance-statistic-report>.wb-row>.wb-col>.card.head {
    width: 230px;
}


/* .bodyM .wallboard.network-performance-statistic-report>.wb-row>.wb-col>.card.head.more,
.bodyM .wallboard.network-performance-statistic-report>.wb-row>.wb-col>.card.tail.more {
    height: 150px;
} */

.bodyX .wallboard.network-performance-statistic-report {
    font-size: 100%;
}

.bodyX .wallboard.network-performance-statistic-report>.wb-row>.wb-col>.card.head,
.bodyX .wallboard.network-performance-statistic-report>.wb-row>.wb-col>.card.tail {
    height: 100px;
    width: 200px;
}

.traffic{
    border: 1px solid #b3aeae;
    padding: 2px 6px;
    border-radius: 10px;
}

/* .bodyX .wallboard.network-performance-statistic-report>.wb-row>.wb-col>.card.head.more,
.bodyX .wallboard.network-performance-statistic-report>.wb-row>.wb-col>.card.tail.more {
    height: 130px;
} */

@media only screen and (max-width: 1200px) {
    .bodyX .wallboard.network-performance-statistic-report>.wb-row>.wb-col>.card.head,
    .bodyX .wallboard.network-performance-statistic-report>.wb-row>.wb-col>.card.tail {
        height: 60px;
    }
    .bodyL .wallboard.network-performance-statistic-report>.wb-row>.wb-col>.card.head,
    .bodyL .wallboard.network-performance-statistic-report>.wb-row>.wb-col>.card.tail {
        height: 60px;
    }
    .bodyM .wallboard.network-performance-statistic-report>.wb-row>.wb-col>.card.head,
    .bodyM .wallboard.network-performance-statistic-report>.wb-row>.wb-col>.card.tail {
        height: 60px;
    }
}
.cp_cat_2 {
    display: none;
}

.cp_cat_2.active {
    display: block;
}

.btn-toggle,
.btn-toggle:hover {
    display: inline-block;
    text-decoration: none;
}

.team .btn-toggle .fa-angle-down {
    display: block;
}

.team .btn-toggle .fa-angle-up {
    display: block;
}

.table-no-pad th,
.table-no-pad td {
    padding: 0
}
.thTableV2 {
    background-color: gray;
    color: white;
    padding: 5px 0;
    font-size: 13px;
    min-width: 25px;
}

.tdTableV2 {
    font-size: 90%;
    font-weight: 700;
}

.percent {
    font-size: 80%;
    color: rgb(119, 62, 62);
    margin: 0;
}

